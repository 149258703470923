import React from "react"
import ReactDOM from "react-dom"
import StyleContext from "isomorphic-style-loader/StyleContext"

// eslint-disable-next-line no-undef
__webpack_public_path__ = process.env.REACT_APP_PUBLIC_URL

const insertCss = (...styles) => {
  const removeCss = styles.map(style => style)
  return () => removeCss.forEach(dispose => dispose())
}

window.__render_component = function(id, componentId, props) {
  return import(`./${componentId}`).then(({ default: Component }) => {
    ReactDOM.hydrate(
      <StyleContext.Provider value={{ insertCss }}>
        <Component {...props} />
      </StyleContext.Provider>,
      document.getElementById(id)
    )
  })
}

window.dispatchEvent(new Event("RenderComponentDeclared"))